.d-flex{
    display: flex;
}
.align-center{
    align-items: center;
}
.flex-center{
    justify-content: center;
}
.flex-column{
    flex-direction: column;
}
.text-white{
    color: #fff;
}
.pr-16{
    padding-right:16px !important;
}

.MuiTableRow-root .MuiTableCell-head {
    padding-left: 20px;
    padding-right: 20px;
}

.MuiTableRow-root .MuiTableCell-root {
    padding-left: 20px;
    padding-right: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.MuiTableRow-root .MuiTableCell-paddingNone {
    padding: 0px;
}

.MuiIconButton-root {
    cursor: pointer;
    width: 30px;
    height: 30px;
}

.asset_department {
    background-color: #fff;
    padding: 8px;
    height: auto;
}

.w-60 {
    width: 60%;
}

.margin-auto {
    margin: auto;
}

.none_wrap {
    white-space: nowrap !important;
}

.validator-form-scroll-dialog {
    overflow-y: auto !important;
    display: flex !important;
    flex-direction: column !important;
}

.logo-login {
    max-width: 400px !important;
}
.logo-login img {
    width: 100% !important;
}

// .logo-menu{
//     max-width: 100px !important;
// }
// .logo-menu img{
//     width: 100% !important;
// }
body {
    @media (min-width: 600px) {
        .mobile {
            padding: 32px;
        }
    }

    @media (max-width: 960px) {
        .pr_mobile {
            padding-right: 0;
        }
    }

    @media (max-width: 600px) {
        .mobile_scroll {
            overflow: scroll !important;
        }
    }
}
.loading-overlay.active{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
    cursor: none;
    display: flex;
    align-items: center;
    justify-content: center
}
.disable-pointer-event.active{
    pointer-events: none;

}
.disable-pointer-event {
    height: 100%;
} 

.MuiOutlinedInput-inputMarginDense{
    padding: 4px;
}

.MuiOutlinedInput-input{
    padding: 8px 0 10px 14px !important;
}

.MuiButton-containedSizeSmall{
    padding: 0 2px !important;
}

.MuiButton-root.datePicker{
    padding: 4px !important;
}

.MuiOutlinedInput-input text{
    font-size: 10px !important;
}

.MuiInputBase-root{
    font-size: 13px !important;
}

.MuiOutlinedInput-adornedEnd{
    padding-right: 5px !important;
}

.MuiFormLabel-root{
    font-size: 13px !important;
}

.MuiButton-contained.Mui-disabled{
    color: rgba(0,0,0,0.74) !important;
}

.MuiButton-root.selectSpeciment{
    padding: 4px 12px !important;
}

.MuiAutocomplete-inputRoot{
    padding: 0 !important;
}

.MuiInputLabel-outlined{
    transform: translate(14px, 12px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink{
    transform: translate(14px, -6px) scale(0.75) !important;
}

.textValidatorCustom .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
    border-width: 2px !important;
}
.textValidatorCustom .MuiOutlinedInput-notchedOutline{
    border-color: red !important;
    border-width: 1px !important;
}

.textValidatorCustom .MuiFormLabel-root{
    color: red !important;
}