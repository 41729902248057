.egret-customizer {
  display: flex;
  flex-direction: column;
  width: 320px;
  position: fixed;
  right: 0;
  box-shadow: $elevation-z12;
  z-index: 50;
  top: 0;
  height: 100vh;
  .customizer-close {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  .layout-boxes {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
    .layout-box {
      width: calc(50% - 16px);
      margin: 12px 8px;
      cursor: pointer;
      > div {
        overflow: hidden;
        display: flex;
        position: relative;
        height: 76px;
        width: 100%;
        img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  .colors {
    display: flex;
    flex-wrap: wrap;
    .color {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      margin-top: 4px;
      margin-right: 12px;
      margin-bottom: 12px;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
      .light, .dark {
        position: absolute;
        border: 12px solid transparent;
        transform: rotate(45deg);
        bottom: -12px;
        left: -12px;
        border-radius: 50%;
      }
      .light {
        border-top-color: rgba(215, 215, 215, 0.6);
      }
      .dark {
        // border-top-color: rgb(34, 41, 69);
        border-top-color: rgba(0, 0, 0, .5);
      }
    }
  }
}
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}